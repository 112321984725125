export default {
  isEnvironmentVisible: true,
  isBrandingVisible: true,
  isWidgetMaterialVisible: true,
  isGenerationCrmPlan3dResultHighVisible: true,
  isCustomersVisible: true,
  isDatasetSelectionPlansVisible: true,
  isCanGetPlansToWorkVisible: true,
  isHasPanoramaCheckboxVisible: true,
  isBetaTestingCheckboxVisible: true,
  isWaitDurationVisible: true,
  isWidgetLinkConstructorVisible: true,
  isSamePlansAiVisible: true,
  isPlanDescriptionAndRendersVisible: true,
  isNewPlanOptionsVisible: true,
};
